<template>
  <div class="register-step2">
    <top-bar back backroute="auth" is-auth :img="logo" />
    <div class="container">
      <div class="title"><t name="2.6" /></div>
      <div class="content"><t name="3.17" /></div>
      <n-input type="phone" :placeholder="$app.secure.clean($n.t('3.18'))" v-bind="$form.input('name')"></n-input>
      <n-button wide color="yellow" @click="next"><t name="3.13" /></n-button>
    </div>
  </div>
</template>

<script>
import logo from 'assets/little-logo.png'
import img from 'assets/phone.png'

export default {
  name: 'PageFinishRegister',
  data() {
    return {
      logo, img,
      cities: [],
    }
  },
  computed: {
    role() {
      return this.$route.query.role
    },
    phone() {
      return this.$route.query.phone
    },
  },
  created() {
    this.$form.init({
      name: '',
    })
    this.$form.rules({
      name: [ 'required', ],
    })
  },
  methods: {
    next() {
      if (this.$form.check()) {
        const data = $n.getLocalVal('regData')
        data.fullname = this.$form.get('name')
        this.$n.saveLocal('regData', data)
        console.log($n.getLocalVal('regData'))
        this.$router.push({ name: 'register.selectCategory', })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.register-step2{
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    margin: 0 auto 8px;
  }
  .wrap {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #D9D9D9;
      padding: 5px;
      margin-bottom: 10px;
      min-height: 44px;
      .right {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .left {
        display: flex;
        align-items: center;
        justify-content: center;

        .title{
          font-weight: 400;
          margin-bottom: 0;
          font-size: 18px;
          color: #102E4F;
        }
      }
    }
  }
  .title {
    margin-top: 70px;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }
  .content {
    font-size: 16px;
    text-align: center;
    color: #102E4F;
    margin-bottom: 24px;
  }

  .n-input{
    margin-bottom: 16px;
  }

  .n-button{
    position: relative;

    a{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
</style>
